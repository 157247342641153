<template>
    <div>
        <h4 class="bold text-uppercase">{{ $route.name }}</h4>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a
                    class="nav-link active"
                    @click="handleShowPanel('order')"
                    id="order-tab"
                    data-toggle="tab"
                    href="#order"
                    role="tab"
                    aria-controls="order"
                    aria-selected="true"
                    >Tạo tiến trình</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('list')"
                    id="list-tab"
                    data-toggle="tab"
                    href="#list"
                    role="tab"
                    aria-controls="list"
                    aria-selected="false"
                    >Danh sách VIP</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('logs')"
                    id="list-tab"
                    data-toggle="tab"
                    href="#list"
                    role="tab"
                    aria-controls="list"
                    aria-selected="false"
                    >Nhật ký chạy VIP</a
                >
            </li>
        </ul>
        <div class="tab-content mt-3" id="myTabContent">
            <div class="tab-pane fade show active" id="order" role="tabpanel" aria-labelledby="order-tab">
                <div class="row">
                    <div class="col-md-8">
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Link hoặc ID profile cần cài VIP:</h6>
                            </div>
                            <div class="col-md col-12">
                                <input
                                    v-model="fb_id"
                                    type="text"
                                    class="form-control input light"
                                    placeholder="Nhập link hoặc ID cần tăng"
                                />
                                <div class="card card-orange mt-2">
                                    <div class="card-body py-2">
                                        <h6 class="font-14 mb-0">
                                            <label class="mb-0 text-danger font-bold"
                                                >Get ID Facebook từ Link nhanh tại
                                                <a
                                                    href="https://findids.net/"
                                                    target="_blank"
                                                    class="bold font-14 mb-0 blue"
                                                    >Findids.net</a
                                                ></label
                                            >
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Chọn cảm xúc:</h6>
                            </div>
                            <div class="col-md col-12">
                                <div class="fb-reaction">
                                    <div class="checkbox" v-for="reaction in reactions" :key="reaction.name">
                                        <label>
                                            <img
                                                :src="reaction.image"
                                                :class="[
                                                    'img-responsive image-reaction ml-2',
                                                    reactionSelected.indexOf(reaction) > -1 ? 'image-active' : ''
                                                ]"
                                                alt="Image"
                                            />
                                            <input type="checkbox" v-model="reactionSelected" :value="reaction" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group" v-for="(reaction, index) in reactionSelected" :key="index">
                            <blockquote class="mb-0">
                                <h6 class="mb-3 text-center text-danger">
                                    Giá tiền mỗi tương tác
                                    <span class="font-bold text-danger text-uppercase">{{ reaction.name }}</span> là:
                                    <span class="font-bold text-uppercase">{{ reaction.price }}</span> {{ currency }}/1
                                    <span class="font-bold text-danger text-uppercase">{{ reaction.name }}</span>
                                </h6>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label
                                            >Số lượng
                                            <b class="font-bold"
                                                ><span class="font-bold text-danger text-uppercase">{{
                                                    reaction.name
                                                }}</span></b
                                            >
                                            nhỏ nhất cần tăng mỗi bài viết:</label
                                        >
                                        <input
                                            type="number"
                                            placeholder="Số lượng cần tăng trên mỗi bài viết"
                                            v-model="reaction.min"
                                            class="form-control input-light"
                                        />
                                    </div>
                                    <div class="col-md-6">
                                        <label
                                            >Số lượng
                                            <b class="font-bold"
                                                ><span class="font-bold text-danger text-uppercase">{{
                                                    reaction.name
                                                }}</span></b
                                            >
                                            lớn nhất cần tăng mỗi bài viết:</label
                                        >
                                        <input
                                            type="number"
                                            placeholder="Số lượng cần tăng trên mỗi bài viết"
                                            v-model="reaction.max"
                                            class="form-control input-light"
                                        />
                                    </div>
                                </div>
                            </blockquote>
                        </div>
                        <div class="row form-group">
                            <div :class="['col-md-6']">
                                <label class="bold">Số lượng bài viết cần tăng trong 1 ngày</label>
                                <input
                                    type="number"
                                    placeholder="Số lượng bài viết cần tăng trong 1 ngày"
                                    v-model="max_post_daily"
                                    class="form-control input-light"
                                />
                            </div>
                            <div class="col-md-6">
                                <label class="bold">Số ngày cần mua Vip:</label>
                                <input
                                    type="number"
                                    placeholder="Số ngày cần mua vip"
                                    v-model="days"
                                    class="form-control input-light"
                                />
                            </div>
                        </div>
                        <div class="form-group col-md-12">
                            <div class="card card-orange">
                                <div class="card-body text-center bold">
                                    Tổng tiền của gói vip sẽ = (Giá tiền mỗi loại cảm xúc) x (Số lượng lớn nhất mỗi loại
                                    cảm xúc) x (Số lượng bài trong ngày) x (Số ngày đăng ký vip)
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Ghi chú:</h6>
                            </div>
                            <div class="col-md col-12 text-right">
                                <textarea
                                    class="form-control input-light"
                                    rows="2"
                                    v-model="notes"
                                    placeholder="Nhập nội dung ghi chú về tiến trình của bạn"
                                ></textarea>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-12 text-center bold">
                                <div class="card card-info">
                                    <div class="card-body">
                                        <h5 class="font-bold">
                                            Tổng:
                                            <span class="bold"
                                                ><number
                                                    ref="number1"
                                                    :from="0"
                                                    :to="totalPrice"
                                                    :format="theFormat"
                                                    :duration="1"
                                                    :delay="0"
                                                    easing="Power1.easeOut"
                                                />
                                                {{ currency }}</span
                                            >
                                        </h5>
                                        <h6 class="mb-0">
                                            <span
                                                >Bạn sẽ mua vip
                                                <span class="bold">{{ max_like }} {{ type_text }}</span> trong
                                                <span class="bold">{{ days }} ngày</span> với giá
                                                <span class="bold"
                                                    >{{ (price * 30) | formatNumber }} {{ currency }}/ 1 like/ 1
                                                    tháng</span
                                                >.</span
                                            >
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-12 text-center bold">
                                <button
                                    @click="order()"
                                    :class="['btn btn-block btn-dark-blue btn-lg btn-rounded bold']"
                                    type="submit"
                                >
                                    Tạo tiến trình
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card card-red my-3">
                            <div class="card-body color-info">
                                <p class="font-bold">Chú ý:</p>
                                <p class="font-bold">
                                    - Hệ thống chỉ tăng Like cho các bài viết đăng mới, không hỗ trợ các bài viết thêm
                                    hình ảnh vào album đã có sẵn.
                                </p>
                                <p class="font-bold">
                                    - Ngiêm cấm Buff các ID Seeding có nội dung vi phạm pháp luật, chính trị, đồ trụy...
                                    Nếu cố tình buff bạn sẽ bị trừ hết tiền và band khỏi hệ thống vĩnh viễn, và phải
                                    chịu hoàn toàn trách nhiệm trước pháp luật.
                                </p>
                                <p>
                                    - Hệ thống sử dụng 99% tài khoản người VN, fb thật để tương tác like, comment,
                                    share....
                                </p>
                                <p>
                                    - Hỗ trợ trang cá nhân và fanpage. Khi đăng bài viết mới hệ thống sẽ tự động quét và
                                    tăng tương tác theo yêu cầu của gói
                                </p>
                                <p>
                                    - Tốc độ tăng nhanh chậm ngẫu nhiên tránh bị facebook phát hiện. Không bảo hành số
                                    lượng tương tác, Tự động hoàn tiền số ngày chưa sử dụng nếu bạn hủy vip
                                </p>
                                <p class="mb-0">
                                    - Vui lòng lấy đúng id vip cần tạo và check kỹ vip tránh tạo nhầm, tính năng đang
                                    trong giai đoạn thử nghiệm nên sẽ không hoàn tiền nếu bạn tạo nhầm
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="list" role="tabpanel" aria-labelledby="list-tab">
                <datatable :columns="panel == 'logs' ? columnsTableLogsOrder : columnsTableLogs" :rows="list_orders">
                    <th v-if="panel != 'logs'" slot="thead-tr">
                        Thao Tác
                    </th>
                    <td
                        v-if="panel != 'logs'"
                        slot="tbody-tr"
                        slot-scope="props"
                        class="text-center"
                        style="vertical-align: middle;"
                    >
                        <button
                            v-if="type == 'vip_seeding_like'"
                            @click.stop.prevent="reNew_Order(props.row)"
                            type="button"
                            :class="['btn btn-sm btn-success mr-2']"
                        >
                            <i class="fas fa-cart-plus"></i>
                            Gia Hạn
                        </button>
                        <button
                            @click.stop.prevent="deleteVip(props.row)"
                            type="button"
                            :class="['btn btn-sm btn-danger align-items-center']"
                        >
                            <i class="fa fa-trash"></i>
                            Hủy Vip
                        </button>
                    </td>
                </datatable>
            </div>
        </div>
    </div>
</template>
<script>
import { catchError, formatNumber } from "../../../../helpers"
import {
    orderVipReaction,
    deleteVipReaction,
    getListVipReaction,
    getLogVip,
    convertUsernameToFbId
} from "../../../../api/services/facebook"
import datatable from "../../../../components/datatable/datatable"
export default {
    name: "facebook-order",
    components: {
        datatable
    },
    data() {
        return {
            list_orders: [],
            type: "like",
            quantity: 1,
            object_id_search: "",
            gender: "",
            fb_id: "",
            columnsTableLogs: [
                { label: "#", field: "index", index: true },
                { label: "Username", field: "username" },
                { label: "Uid", field: "info_user", html: true },
                // { label: 'Min', field: 'min_like', numeric: true },
                // { label: 'Max', field: 'quantity', numeric: true },
                { label: "Loại", field: "type_reaction", html: true },
                { label: "Posts", field: "max_post_daily", numeric: true },
                { label: "Số Ngày", field: "days", numeric: true },
                // { label: 'Giá', field: 'price_per', numeric: true },
                { label: "Tổng Tiền", field: "prices", numeric: true },
                { label: "Hết Hạn", field: "time_expired", timeago: true },
                { label: "Khởi Tạo", field: "created_at", timeago: true },
                { label: "Ghi chú", field: "notes", textarea: true }
            ],
            columnsTableLogsOrder: [
                { label: "#", field: "index", index: true },
                { label: "Username", field: "username" },
                { label: "Uid", field: "fb_id", fb_link: true },
                { label: "Object Id", field: "object_id", fb_link: true },
                { label: "Loại", field: "type", html: true },
                { label: "Khởi Tạo", field: "created_at", timeago: true }
            ],
            price: 0,
            rows: [],
            days: 30,
            max_post_daily: 2,
            min_like: 50,
            max_like: 50,
            list_messages: [],
            notes: "",
            total_post: 10,
            panel: "",
            reNewOrder: {},
            price_default: 0,
            reactions: [
                {
                    name: "like",
                    min: 10,
                    max: 20,
                    price: 50,
                    image: "assets/images/fb-reaction/like.svg"
                },
                {
                    name: "love",
                    min: 10,
                    max: 20,
                    price: 50,
                    image: "assets/images/fb-reaction/love.svg"
                },
                {
                    name: "haha",
                    min: 10,
                    max: 20,
                    price: 50,
                    image: "assets/images/fb-reaction/haha.svg"
                },
                {
                    name: "wow",
                    min: 10,
                    max: 20,
                    price: 50,
                    image: "assets/images/fb-reaction/wow.svg"
                },
                {
                    name: "sad",
                    min: 10,
                    max: 20,
                    price: 50,
                    image: "assets/images/fb-reaction/sad.svg"
                },
                {
                    name: "angry",
                    min: 10,
                    max: 20,
                    price: 50,
                    image: "assets/images/fb-reaction/angry.svg"
                }
            ],
            reactionSelected: [],
            type_reaction: "like",
            reaction_config: {}
        }
    },
    computed: {
        currency() {
            return this.$store.state.site.site.currency
        },
        prices() {
            let tool = this.$store.state.tools.tools.find(tool => tool.id === 69)
            return tool.prices
        },
        priceSingle() {
            let price = parseInt(this.price)
            return Math.ceil(price)
        },
        totalPrice() {
            let prices = 0
            for (const reaction of this.reactionSelected) {
                prices += reaction.max * reaction.price
            }
            return prices * this.max_post_daily * this.days
        },
        type_text() {
            let type = ""
            for (const reaction of this.reactionSelected) {
                type += reaction.max + " " + reaction.name + ", "
            }
            return type.substring(0, type.length - 2)
        },
        router() {
            return this.$route.path
        }
    },
    watch: {
        async fb_id() {
            if (this.fb_id.indexOf("facebook.com") > -1) {
                this.fb_id = await this.getFbId(this.fb_id)
            }
        },
        reactionSelected(value) {
            let prices = this.prices
            this.quantity = 0
            for (const reaction of value) {
                let getPackage =
                    "seeding_by_workers_" + (this.is_warranty ? reaction.name + "_warranty" : reaction.name)
                let price = prices.find(price => price.package_name === getPackage)
                this.quantity += Number(reaction.max)
                if (price) {
                    reaction.price = price.price
                }
            }
        }
    },
    created() {
        let prices = this.prices
        let price = prices.find(price => price.package_name === this.type)
        if (price) {
            this.price_default = price.price
            this.price = price.price
        }
    },
    methods: {
        order: async function() {
            if (this.reactionSelected.length < 1) {
                this.$swal.fire("Lỗi", "Bạn chưa chọn loại cảm xúc để cài VIP", "error")
                return
            }
            if (this.fb_id.length < 5) {
                this.$swal.fire("Lỗi", "Vui lòng nhập Id cần cài VIP", "error")
                return
            }
            this.reaction_config = {}
            this.quantity = 0
            for (const reaction of this.reactionSelected) {
                this.reaction_config[reaction.name] = {}
                this.reaction_config[reaction.name].quantity = reaction.max
                this.reaction_config[reaction.name].min = reaction.min
                this.reaction_config[reaction.name].max = reaction.max
                this.quantity += Number(reaction.max)
                if (reaction.min > reaction.max) {
                    this.$swal.fire(
                        "Lỗi",
                        "Min " + reaction.name.toUpperCase() + " phải nhỏ hơn max " + reaction.name.toUpperCase(),
                        "error"
                    )
                    return
                }
                if (reaction.max < 10) {
                    this.$swal.fire(
                        "Lỗi",
                        "Số lượng tối thiểu " +
                            reaction.name.toUpperCase() +
                            " phải là: 10 " +
                            reaction.name.toUpperCase(),
                        "error"
                    )
                    return
                }
            }
            let check = await this.$swal.fire({
                title: "Xác nhận?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "OK",
                reverseButtons: true,
                html: `Bạn có chắc chắn muốn đăng ký gói VIP ${this.type_text.toUpperCase()}:<br>- Thời gian ${
                    this.days
                } ngày <br> - ${
                    this.max_post_daily
                } bài viết / ngày<br> - ${this.type_text.toUpperCase()} / bài viết<br>=> Tổng tiền VIP ${
                    this.type_text
                }: <b> ${formatNumber(this.totalPrice)}</b> ${this.currency}.`
            })
            if (!check.value) {
                return
            }
            let postData = {
                fb_id: this.fb_id,
                days: this.days,
                // 'price_per': this.price,
                quantity: this.quantity,
                notes: this.notes,
                max_post_daily: this.max_post_daily,
                reaction_config: this.reaction_config
            }
            let data = await orderVipReaction(postData)
            if (data.status === 200 && data.success) {
                this.$swal.fire("Thành công", "Tạo gói Vip thành công !", "success")
            } else {
                let noti = ""
                if (data.message) {
                    if (catchError(data) === data.message) {
                        noti = data.message
                    } else noti = catchError(data) + "</br></br>" + data.message
                } else noti = catchError(data)
                this.$swal.fire("Lỗi", noti, "error")
            }
        },
        deleteVip: async function(vip) {
            let check = await this.$swal({
                title: "Xác nhận?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "OK",
                html: `Bạn có chắc chắn muốn hủy gói VIP này? <br> Bạn sẽ được hoàn lại số tiền những ngày vip chưa sử dụng và bị trừ thêm 10.000 ${this.currency} tiền phí hủy để tránh spam`
            })
            if (!check.value) {
                return
            }
            let param = {
                id: vip.id
            }
            let data = await deleteVipReaction(param)
            if (data.status === 200 && data.success) {
                let index = this.list_orders.indexOf(vip)
                this.list_orders.splice(index, 1)
                this.$swal("Thông tin", "Bạn đã hủy vip thành công", "success")
            } else {
                this.$swal("Thông tin", data.message || "Bạn đã hủy vip thất bại", "error")
            }
        },
        getListVipSeedingOrder: async function() {
            let data = await getListVipReaction()
            if (data.data) {
                this.list_orders = data.data.map(order => {
                    order.info_user = `<img src="https://graph.facebook.com/${order.fb_id}/picture?width=100&height=100" width="36" alt="user${order.fb_id}" class="active rounded-circle">`
                    order.info_user += `<a href="https://wwww.facebook.com/${order.fb_id}" target="_blank" class="font-bold text-info ml-2">${order.fb_name}</a>`
                    order.reaction_config = order.reaction_config ? JSON.parse(order.reaction_config) : []
                    let reactions = Object.keys(order.reaction_config).map(i => order.reaction_config[i])
                    order.type_reaction = ""
                    let today = new Date()
                    if (today < new Date(order.time_expired)) {
                        order.checkDone = true // true: chưa hết hạn
                    } else {
                        order.checkDone = false // false: hết hạn
                    }
                    for (const reaction of reactions) {
                        order.type_reaction += `<span class="badge badge-info mr-1 ml-2">${reaction.max}</span>`
                        for (const item of this.reactions) {
                            if (reaction.type === item.name) {
                                order.type_reaction += `<img src="${item.image}" class="b-block" alt="Image" style="width:22px">`
                                break
                            }
                        }
                    }
                    order.type_html = order.type.toUpperCase()
                    order.list_messages_html = order.list_messages ? JSON.parse(order.list_messages).join("\n") : ""
                    order.status_html = order.is_refund
                        ? '<span class="badge badge-warning">Hoàn tiền</span>'
                        : (order.count_is_run >= order.quantity
                              ? '<span class="badge badge-success">Hoàn thành</span>'
                              : '<span class="badge text-white badge-primary">Đang chạy</span>') +
                          `<span class="badge badge-success ml-1">${order.count_success}</span><span class="badge badge-danger ml-1">${order.count_error}</span>`
                    return order
                })
            }
        },
        getLogVipSeedingOrder: async function() {
            let data = null
            if (this.object_id_search) {
                data = await getLogVip(this.object_id_search, "vip_seeding_reaction", this.limit_logs)
            } else {
                data = await getLogVip("", "vip_seeding_reaction", this.limit_logs)
            }
            if (data.data.data) {
                this.list_orders = data.data.data.map(order => {
                    order.type_html = order.type.toUpperCase()
                    order.list_messages_html = order.list_messages ? JSON.parse(order.list_messages).join("\n") : ""
                    order.status_html = order.is_refund
                        ? `<span class="badge badge-warning">Hoàn tiền</span>`
                        : (order.count_is_run >= order.quantity
                              ? `<span class="badge badge-success">Hoàn thành</span>`
                              : `<span class="badge text-white badge-primary">Đang chạy</span>`) +
                          `<span class="badge badge-success ml-1">${formatNumber(
                              order.count_success
                          )}</span><span class="badge badge-danger ml-1">${formatNumber(order.count_is_run)}</span>`
                    return order
                })
            }
        },
        handleShowPanel: async function(panel) {
            this.panel = panel
            if (panel === "list") {
                this.list_orders = []
                await this.getListVipSeedingOrder()
            }
            if (panel === "logs") {
                this.list_orders = []
                await this.getLogVipSeedingOrder()
            }
        },
        theFormat(number) {
            return number
                ? Math.round(Number(number))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0
        },
        getFbId: async function(link) {
            if (link.match(/facebook/)) {
                this.$toastr.success("Hệ thống đang tự động get id cho link facebook bạn nhập vào!")
                if (link.indexOf("profile.php") > -1) {
                    var regex = (link + "&").match(/id=(\d*?)&/)
                    if (regex.length === 2) {
                        this.$toastr.success("Đã tự động get id cho bạn")
                        return regex[1]
                    } else {
                        return false
                    }
                } else {
                    var username = link.replace(
                        /https|http|\/|\\|www\.|mbasic\.|m\.facebook.com|facebook\.com|:|\?.*/gm,
                        ""
                    )
                    let data = await convertUsernameToFbId(username)
                    if (data.status === 200 && data.success) {
                        this.$toastr.success("Đã tự động get id cho bạn")
                        return data.data.id
                    } else {
                        return false
                    }
                }
            } else return true
        }
    }
}
</script>
